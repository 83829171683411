<ng-container *ngIf="experience && showDrawer">
  <atc-side-drawer (closeChange)="closeDrawer()"
                   [side]="windowResource.isDesktop ? 'right' : 'top'"
                   [title]="experience.name">
    <div class="m-auto flex h-[6.5rem] max-w-[75%] flex-col items-center justify-center" header>
      <h1>{{ experience.name }}</h1>
      <h3 class="text-center">Réserver votre activité</h3>
    </div>

    <div body class="xl:px-8">
      <ng-container *ngIf="!experience.internships.length">
        <div class="block-select">
          <div class="mb-2 flex items-center justify-between">
            <div>
              <h2>{{
                  experience.ski || experience.equipment
                    ? '1. Choisissez votre premier jour :'
                    : '1. Choisissez une date :'
                }}
              </h2>
              <p class="mb-2">Quand souhaitez-vous pratiquer l'activité pendant votre séjour ?</p>
            </div>
            <div>
              <svg class="h-8 w-8 stroke-black">
                <use [attr.xlink:href]="'assets/icons/icons.svg#clock'"></use>
              </svg>
            </div>
          </div>
          <atc-date-timeline-selector (selectedDateChanged)="dateSelectedChange($event)"
                                      [daysDisabled]="getDaysDisabled(period)"
                                      [endDate]="period.endDate"
                                      [selectedDate]="currentDay"
                                      [startDate]="period.startDate">
          </atc-date-timeline-selector>
        </div>
        <div *ngIf="!experience.ski && !experience.equipment" class="block-select">
          <h2>2. Choisissez votre créneau :</h2>
          <p *ngIf="timeSlotsExperience.length" class="mb-2">Quel horaire vous convient le mieux ?
          </p>
          <p *ngIf="getWarningSlot(this.currentTimeSlotExperience?.remainingSlots) as warning"
             class="warning mb-2">
            {{ warning }}
          </p>
          <div *ngIf="timeSlotsExperience && timeSlotsExperience.length"
               class="grid grid-cols-3 gap-2 py-2">

            <atc-button-select (selectedChanged)="timeSlotSelectedChange(i)"
                               *ngFor="let timeSlot of timeSlotsExperience; let i = index"
                               [label]="getLabelTimeSlot(timeSlot)"
                               [selected]="currentTimeSlotIndex === i"
                               class="flex">
            </atc-button-select>

          </div>
          <p *ngIf="!timeSlotsExperience.length">Aucun créneaux disponibles...</p>
        </div>
      </ng-container>

      <ng-container *ngIf="experience.internships.length">
        <div class="block-select">
          <div class="flex items-center justify-between">
            <div>
              <h2>1. Choisissez votre créneau :</h2>
              <p class="mb-2">Quel horaire vous convient le mieux ?</p>
            </div>
            <div>
              <svg class="h-8 w-8 stroke-black">
                <use [attr.xlink:href]="'assets/icons/icons.svg#clock'"></use>
              </svg>
            </div>
          </div>

          <p *ngIf="getWarningSlot(this.currentInternship?.slotAvailable) as warning" class="mb-2">
            {{ warning }}
          </p>
          <div *ngFor="let internship of experience.internships; let i = index"
               [ngClass]="{'internship-single': internship.timeSlots.length === 1}">

            <atc-button-line (selectChanged)="timeSlotSelectedChange(i)"
                             [selected]="currentInternshipIndex === i"
                             [slots]="mapToSlots(internship)">
            </atc-button-line>
          </div>
        </div>
      </ng-container>

      <div *ngIf="timeSlotsExperience.length || experience.hasInternship"
           class="block-select">
        <div class="flex items-center justify-between">
          <div>
            <h2>{{ experience.hasInternship || experience.ski || experience.equipment ? 2 : 3 }}.
              Sélectionnez les voyageurs :
              <ng-container *ngIf="PackageUtils.getMinParticipants(packages) > 1">
                (Min. {{ PackageUtils.getMinParticipants(packages) }} voyageur(s))
              </ng-container>
            </h2>
            <p class="mb-2">Qui participe à l'activité ?</p>
            <p *ngIf="participantIsNotAllowed(participants)" class="warning">
              Certain(s) voyageur(s) ne sont pas éligible(s) à cette activité
              ({{ PackageUtils.ageRange(packages) }})
            </p>
          </div>
          <div>
            <svg class="h-8 w-8 stroke-black">
              <use [attr.xlink:href]="'assets/icons/icons.svg#user-group'"></use>
            </svg>
          </div>
        </div>

        <div class="mt-4 flex flex-col gap-4">
          <div class="grid gap-4 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3">
            <ng-container *ngFor="let participant of participants | sortByAgeDesc">
              <atc-button-toggle (selectedChange)="changeParticipant(participant, $event)"
                                 [disabled]="isDisabled(participant)"
                                 [price]="getAgeFromBirthDate(participant.birthdate)"
                                 [selected]="isSelected(participant)"
                                 [subtitle]="participant.firstname"
                                 icon="person"
                                 size="tiny">
              </atc-button-toggle>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex w-full flex-col items-center justify-center gap-4 p-8 lg:gap-2 lg:p-4 lg:pt-0 md:gap-2 md:p-4 md:pt-0"
      footer>
      <atc-tag *ngIf="experience.withAccommodation && !cart.itemsAccommodation.length"
               color="warning"
               size="semilarge">
        Cette activité ne peut se réserver qu'avec un hébergement
      </atc-tag>
      <atc-tag *ngIf="experience.ski && resort" color="info" size="semilarge">
        Achat d'un skipass ({{ resort.priceSkiPass | number: '1.2-2' : 'fr' }} €) ou rechargement au paiement
      </atc-tag>

      <div class="total">
        <h2>Total</h2>
        <div class="flex flex-row gap-x-4">
          <span *ngIf="totalPublic !== total">
            {{ totalPublic | number: '1.2-2' : 'fr' }} €
          </span>
          <h3>{{ total | number: '1.2-2' : 'fr' }} €
            <ng-container *ngIf="pricePerParticipant( participantsTimeSlot.length) as pricePerParticipant">
              ({{ pricePerParticipant | number: '1.2-2' : 'fr' }} € / p)
            </ng-container>
          </h3>
        </div>
      </div>

      <div *ngIf="getCategoryPrice(packages) as priceType">
        <p class="warning">{{ priceType }}</p>
      </div>

      <atc-button (click)="registerParticipants($event)"
                  [disabled]="cantAddToCart()"
                  [fullWidth]="true"
                  [label]="editMode ? 'Modifier la réservation' : 'Ajouter au panier'"
                  class="flex w-full"
                  color="secondary"
                  icon="cart">
      </atc-button>

    </div>
  </atc-side-drawer>
</ng-container>
