<!-- cart not empty -->
<ng-container *ngIf="!loading">
  <div class="flex flex-col gap-4">
    <!-- cart empty -->
    <div *ngIf="isEmpty" class="empty-cart">
      <img alt="Panier" src="assets/svg/cart.svg" />
      <h3>Votre panier est vide</h3>
      <ng-container *ngIf="(isFoncia$ | async) === false">
        <p>N'hésitez pas à ajouter un hébergement et à faire le plein d'activités !</p>
      </ng-container>
    </div>

    <!--    <ng-container>-->
    <!--      <div class="header-title">-->
    <!--        <svg>-->
    <!--          <use [attr.xlink:href]="'assets/icons/icons.svg#document'"></use>-->
    <!--        </svg>-->
    <!--        <h1>Frais de Dossier</h1>-->
    <!--      </div>-->

    <!--      <div class="eco-chart">-->
    <!--        <h2>Frais de dossier</h2>-->
    <!--        <p>En acceptant la charte de l'éco-tourimse nous vous offrons les frais de dossier</p>-->

    <!--        <atc-input-checkbox [control]="ecoTourismChart"-->
    <!--                            (checkChanged) = "ecoTourismChart.setValue($event)"-->
    <!--                            class="flex-1">-->
    <!--          En cochant cette case, j’ai lu et j’accepte <a target="_blank" class="underline" [routerLink]="'/eco-tourism-chart'">la charte de l'éco-tourisme de VeryMountain</a><br>-->
    <!--          <span class="charte-infos">{{ ecoTourismChart.getRawValue() ? chartCheckedDescr : chartNotCheckedDescr }}</span>-->
    <!--        </atc-input-checkbox>-->

    <!--        <div class="total">-->
    <!--          <h2>Total</h2>-->
    <!--          <div class="prices">-->
    <!--            <h3 [innerHTML]="ecoChartPrice()"></h3>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </ng-container>-->

    <ng-container *ngIf="(isFoncia$ | async) === false">
      <div class="header-title">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#home'"></use>
        </svg>
        <h4>Hébergement</h4>
      </div>

      <ng-container *ngIf="itemsAccommodation.length">
        <atc-card-cart (click)="redirectToEstablishment(item.establishment, item.room, item.resort, $event)"
                       (deleteItem)="deleteItemAccommodation(item)"
                       (selectedItem)="selectedItem(item, $event)"
                       *ngFor="let item of itemsAccommodation"
                       [address]="item.establishment.address"
                       [endDate]="item.endDate"
                       [participants]="mapItemsParticipants(item.participants)"
                       [pictureName]="item.room.name"
                       [picture]="item.room.pictures[0]"
                       [price]="item.price"
                       [publicPrice]="item.publicPrice"
                       [selectForm]="item.selectForm"
                       [selectable]="isProposition"
                       [showDelete]="canDeleteItem && !isProposition"
                       [startDate]="item.startDate"
                       [subtitle]="item.room.name"
                       [tag]="item.baseProductLabel"
                       [title]="item.establishment.name">
        </atc-card-cart>
      </ng-container>
    </ng-container>
    <a (click)="close()"
       *ngIf="!itemsAccommodation?.length && canDeleteItem"
       [routerLink]="getLinkAccommodations()"
       class="mb-4"
       queryParamsHandling="merge"
       routerLinkActive="true">
      <atc-button [color]="isPayment ? 'secondary' : 'primary'"
                  icon="home"
                  label="Ajouter un hébergement">
      </atc-button>
    </a>

    <div class="header-title">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
      </svg>
      <h4>Activité{{ itemsActivity.length > 1 ? 's' : '' }}</h4>
    </div>
    <ng-container *ngIf="itemsActivity.length">
      <div *ngFor="let item of itemsActivity" class="flex flex-col gap-y-4">
        <div *ngIf="shouldPrintDate(item)" class="flex flex-row justify-evenly items-center header-activity">
          <hr>
          <p class="m-0 w-full text-center">{{ item.startDate.locale('fr').format('dddd DD MMMM') }}</p>
          <hr>
        </div>

        <atc-card-cart (click)="redirectToExperience(item.item?.experience?.id, item.resort, $event)"
                       (deleteItem)="deleteItem(item)"
                       (editItem)="editItem(item)"
                       (selectedItem)="selectedItem(item, $event)"
                       [address]="item.item?.experience.address || ''"
                       [endDate]="item.internshipId ? item.endDate : undefined"
                       [endHour]="formatHour(item, true)"
                       [nbCardToBuy]="nbCardToBuy(item)"
                       [participants]="mapItemsParticipants(item.participants)"
                       [pictureName]="item.activity"
                       [picture]="item.photo"
                       [priceCard]="item.ski ? item.skiPass?.price || 0 : 0"
                       [price]="item.price"
                       [publicPrice]="item.pricePublic"
                       [selectForm]="item.selectForm"
                       [selectable]="isProposition"
                       [showDelete]="canDeleteItem && !isProposition"
                       [showEdit]="canEditItem && !isPaymentPage"
                       [startDate]="item.startDate"
                       [startHour]="formatHour(item)"
                       [subtitle]="'Chez ' + item.partnerName"
                       [tag]="item.withAccommodation ? 'Hébergement nécessaire' : ''"
                       [title]="item.title">
        </atc-card-cart>
      </div>
    </ng-container>
    <a (click)="close()"
       *ngIf="canDeleteItem"
       [queryParams]="getQueryParamActivity()"
       [routerLink]="getLinkActivities()"
       class="mb-4"
       queryParamsHandling="merge"
       routerLinkActive="true">
      <atc-button [color]="isPayment ? 'secondary' : 'primary'"
                  icon="activity"
                  label="Ajouter une activité">
      </atc-button>
    </a>

    <ng-container *ngIf="itemsSkiEquipment.length || itemsEquipements.length">
      <div class="header-title">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#skis'"></use>
        </svg>
        <h4>Location de matériel</h4>
      </div>

      <div class="flex flex-col gap-4">
        <p *ngIf="isPaymentPage" class="email-confirmation-info">Suite à votre paiement, nous vous enverrons un e-mail
          avec votre bon de réservation et la liste des magasins dans la
          station où vous pourrez retirer votre matériel.</p>
        <atc-card-cart (deleteItem)="deleteSkiEquipmentItem(item)"
                       (selectedItem)="selectedItem(item, $event)"
                       *ngFor="let item of itemsSkiEquipment"
                       [endDate]="item.endDate"
                       [isMaterial]="true"
                       [participants]="mapItemsParticipants([item.participantSession])"
                       [pictureName]="item.pack.name"
                       [picture]="item.pack.pictureURL"
                       [price]="item.totalPrice"
                       [publicPrice]="item.totalPublicPrice"
                       [selectForm]="item.selectForm"
                       [selectable]="isProposition"
                       [showDelete]="canDeleteItem && !isProposition"
                       [startDate]="item.startDate"
                       [subtitle]="item.pack.description"
                       [title]="item.pack.name"
                       tag="Hébergement nécessaire">
        </atc-card-cart>
        <atc-card-cart (click)="redirectToExperience(equipment.item?.experience?.id, equipment.resort, $event)"
                       (deleteItem)="deleteItem(equipment)"
                       (editItem)="editItem(equipment)"
                       (selectedItem)="selectedItem(equipment, $event)"
                       *ngFor="let equipment of itemsEquipements"
                       [address]="equipment.item?.experience.address || ''"
                       [endDate]="equipment.internshipId ? equipment.endDate : undefined"
                       [nbCardToBuy]="nbCardToBuy(equipment)"
                       [participants]="mapItemsParticipants(equipment.participants)"
                       [pictureName]="equipment.activity"
                       [picture]="equipment.photo"
                       [price]="equipment.price"
                       [publicPrice]="equipment.pricePublic"
                       [selectForm]="equipment.selectForm"
                       [selectable]="isProposition"
                       [showDelete]="canDeleteItem && !isProposition"
                       [showEdit]="canEditItem"
                       [startHour]="formatHour(equipment)"
                       [subtitle]="'Chez ' + equipment.partnerName"
                       [tag]="equipment.withAccommodation ? 'Hébergement nécessaire' : ''"
                       [title]="equipment.title">
        </atc-card-cart>
      </div>
    </ng-container>

    <div *ngIf="windowResource.width() | async | mobile" class="phone">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#phone'"></use>
      </svg>
      <p><b>Une question ?</b> Faites-vous accompagner par notre équipe au <a class="font-bold"
                                                                              href="tel:+33465847469">04 65 84 74 69</a>
      </p>
    </div>
  </div>
</ng-container>

<!-- Loading -->
<div *ngIf="loading" class="min-h-full min-w-full flex justify-center items-center">
  <atc-loader color="secondary"></atc-loader>
</div>
